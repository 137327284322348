<template>
  <quick-quote-page
    id="User Settings"
    :optionsname="$options.name"
  >
    <template #content>
      <user-settings-table
        ref="maintenanceUserSettingsTable"
      />
    </template>
  </quick-quote-page>
</template>

<script>
  import QuickQuotePage from '@/components/QuickQuotePage'
  import UserSettingsTable from '@/components/maintenance/tables/UserSettingsTable'

  export default {
    name: 'UserSettingsView',

    components: {
      QuickQuotePage,
      UserSettingsTable,
    },
  }
</script>

<style scoped>

</style>
